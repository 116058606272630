.leaflet-container {
    width: 100%;
    height: 90vh;
  }


  .img-popup-gyms{
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
  }

  .leaflet-top {
  z-index: 500 !important;
}

.img-btn{
  max-width: 30px;
  max-height: 75px;
  
}