.title-home {
  font-family: "Pokemon Solid", sans-serif;
}



.btn-discord {
  background-color: #7289da!important;
  border-color: #7289da !important ;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
}

.btn-discord:hover {
  background-color: #7289da;
  
}

.btn-pokedex {
  background-color: #818181!important;
  border-color: #010101 !important ;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
}

.btn-pokedex:hover {
  background-color: #7289da;
  
}

.logo-pokedex {
  width: 10%!important;
}

.home-text {
  text-align: center;
}



a:link,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  color: inherit!important;
}



/* Animation des starters */
.fire_box {
  display: inline-block;
  background-color: #ffccbc;
  border: 3px solid #ff5722;
  border-radius: 10px;
  float: left;
  padding: 5px;
  margin: 5px;
}
.water_box {
  display: inline-block;
  background-color: #b3e5fc;
  border: 3px solid #03a9f4;
  border-radius: 10px;
  float: left;
  padding: 5px;
  margin: 5px;
}
.grass_box {
  display: inline-block;
  background-color: #dcedc8;
  border: 3px solid #7cb342;
  border-radius: 10px;
  float: left;
  padding: 5px;
  margin: 5px;
}

#avatar {
  position: absolute;
  z-index: 5;
  float: left;
}

/*-------------*/
/* FIRE AVATAR */
/*-------------*/
.fire_avatar {
  height: 200px;
  width: 167px;
  border-radius: 50% / 55% 55% 45% 45%;
  border: 5px solid #ff5722;
  margin: 5px;
  background: url(https://i.imgur.com/EJWHoBE.jpg);
  background-position: bottom center;
  background-color: #b30000;
  background-clip: content-box;

  position: relative;
  float: left;
}

.inneravatar-6 {
  width: 100%;
  height: 100%;
  position: relative;
}
.inneravatar-6 div {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transform: rotate(-45deg);

  opacity: 0.5;
  filter: alpha(opacity=50);
}
.fire_avatar .inneravatar-6 div:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
}

.fire_avatar .inneravatar-6 div:nth-child(3) {
  width: 100%;
  height: 4em;
  bottom: 0.8em;
}
.fire_avatar .inneravatar-6 div:nth-child(3):after {
  background-color: yellow;
  background: -moz-linear-gradient(45deg, orange, yellow);

  animation: wiggle 2s ease-in-out infinite alternate,
    width-wiggle 2.7s ease-in-out infinite alternate, flicker 1s linear infinite;
}

.fire_avatar .inneravatar-6 div:nth-child(2) {
  width: 7em;
  height: 7em;
  bottom: 0.6em;
}
.fire_avatar .inneravatar-6 div:nth-child(2):after {
  background-color: orange;
  background: -moz-linear-gradient(45deg, red, orange);
  animation: wiggle 2.4s ease-in-out infinite alternate,
    width-wiggle 2.9s ease-in-out infinite alternate,
    flicker 1.8s linear infinite;
}

.fire_avatar .inneravatar-6 div:nth-child(1) {
  width: 10em;
  height: 10em;
  bottom: 0.4em;
}
.fire_avatar .inneravatar-6 div:nth-child(1):after {
  background-color: #b30000;
  background: -moz-linear-gradient(45deg, #b30000, red);
  animation: wiggle 2.9s ease-in-out infinite alternate,
    width-wiggle 3.2s ease-in-out infinite alternate,
    flicker 1.1s linear infinite;
}

@keyframes wiggle {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}
@keyframes width-wiggle {
  from {
    padding-left: 0;
    padding-right: 0;
  }
  to {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*--------------*/
/* WATER AVATAR */
/*--------------*/
/*.water_avatar {
  height: 200px;  width: 167px;  border-radius: 50% / 55% 55% 45% 45%;  padding: 5px;  border: 5px solid #03a9f4;  background-color: #0277bd;  margin: 5px;  position: relative; float: left;
  
/*  background: url(https://i.imgur.com/YG4Esnu.png), url(https://i.imgur.com/2Dch8qu.jpg);
	animation: water-horz-animation 1s infinite linear, water-vert-animation 10s infinite linear alternate;
    -webkit-animation: water-horz-animation 1s infinite linear, water-vert-animation 10s infinite linear alternate;  
	background-size: 200px 100px;
	background-repeat: repeat-x, no-repeat;
  
  background-clip: content-box;
}*/

.water_avatar {
  height: 200px;
  width: 167px;
  border-radius: 50% / 55% 55% 45% 45%;
  padding: 5px;
  border: 5px solid #03a9f4;
  background-color: #0288d1;
  background-clip: content-box;
  margin: 5px;
  position: relative;
  float: left;
}

.inneravatar-8 {
  width: 100%;
  height: 100%;
  border-radius: 50% / 55% 55% 45% 45%;
  position: relative;
  background-clip: content-box;
  overflow: hidden;
}

.inneravatar-8 div {
  position: absolute;
  width: 100%;
  height: 100%;
  background-clip: content-box;

  opacity: 0.5;
  filter: alpha(opacity=50);
}

.water_avatar .inneravatar-8 div:nth-child(3) {
  background-image: url(https://i.imgur.com/NLUf9hm.png);
  background-size: 200px 100px;
  background-repeat: repeat-x;
  animation: water-horz-animation 0.9s infinite linear,
    water-vert-animation 10s infinite linear alternate;
  -webkit-animation: water-horz-animation 0.9s infinite linear,
    water-vert-animation 10s infinite linear alternate;
}

.water_avatar .inneravatar-8 div:nth-child(2) {
  background-size: 200px 100px;
  background-repeat: repeat-x;
  animation: water-horz-animation 1.1s infinite linear,
    water-vert-animation 12s infinite linear alternate;
  -webkit-animation: water-horz-animation 1.1s infinite linear,
    water-vert-animation 12s infinite linear alternate;
}

.water_avatar .inneravatar-8 div:nth-child(1) {
  background-image: url(https://i.imgur.com/ABIaTvk.png);
  background-size: 200px 100px;
  background-repeat: repeat-x;
  animation: water-horz-animation 1.3s infinite linear,
    water-vert-animation 14s infinite linear alternate;
  -webkit-animation: water-horz-animation 1.3s infinite linear,
    water-vert-animation 14s infinite linear alternate;
}

@-webkit-keyframes water-horz-animation {
  0% {
    background-position: 0px bottom, 0 bottom;
  }
  100% {
    background-position: 200px bottom, 0 bottom;
  }
}
@keyframes water-horz-animation {
  0% {
    background-position: 0px bottom, 0 bottom;
  }
  100% {
    background-position: 200px bottom, 0 bottom;
  }
}
@-webkit-keyframes water-vert-animation {
  0% {
    background-size: 200px 50px, 250px 250px;
  }
  100% {
    background-size: 200px 200px, 250px 250px;
  }
}
@keyframes water-vert-animation {
  0% {
    background-size: 200px 50px, 250px 250px;
  }
  100% {
    background-size: 200px 200px, 250px 250px;
  }
}

.host-avatar {
  height: 200px;
  width: 175px;
  border-radius: 50% / 55% 55% 45% 45%;
  padding: 5px;
  border: 5px solid #22c3aa;
  background-color: teal;
  background-clip: content-box;
  margin: 5px;
  float: left;
}

/*--------------*/
/* GRASS AVATAR */
/*--------------*/
.grass_avatar {
  height: 200px;
  width: 167px;
  border-radius: 50% / 55% 55% 45% 45%;
  padding: 5px;
  border: 5px solid #8bc34a;
  background-color: green;
  background-clip: content-box;
  margin: 5px;
  position: relative;
  float: left;
}

.inneravatar-7 {
  width: 100%;
  height: 100%;
  border-radius: 50% / 55% 55% 45% 45%;
  position: relative;
  background-clip: content-box;
  overflow: hidden;
}

.inneravatar-7 div {
  position: absolute;
  top: -1em;
  width: 50%;
  height: 50%;
  margin: auto;

  border-radius: 100% 0%;

  background-color: yellow;
  background: -moz-linear-gradient(45deg, yellow, green);
  opacity: 0.5;
  filter: alpha(opacity=50);

  transform-origin: top right;
  background-clip: content-box;
}
.grass_avatar .inneravatar-7 div:after {
}

.grass_avatar .inneravatar-7 div:nth-child(4) {
  transform: rotate(-26deg);
  animation: wind-4 6s ease-in-out infinite alternate;
}
.grass_avatar .inneravatar-7 div:nth-child(4):after {
}
@keyframes wind-4 {
  0% {
    transform: rotate(-16deg);
  }
  100% {
    transform: rotate(-36deg);
  }
}

.grass_avatar .inneravatar-7 div:nth-child(3) {
  transform: rotate(-53deg);
  animation: wind-3 7s ease-in-out infinite alternate;
}
.grass_avatar .inneravatar-7 div:nth-child(3):after {
}
@keyframes wind-3 {
  0% {
    transform: rotate(-43deg);
  }
  100% {
    transform: rotate(-63deg);
  }
}

.grass_avatar .inneravatar-7 div:nth-child(2) {
  transform: rotate(-80deg);
  animation: wind-2 6.66s ease-in-out infinite alternate;
}
.grass_avatar .inneravatar-7 div:nth-child(2):after {
}
@keyframes wind-2 {
  0% {
    transform: rotate(-65deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.grass_avatar .inneravatar-7 div:nth-child(1) {
  transform: rotate(0deg);
  animation: wind-1 6.33s ease-in-out infinite alternate;
}
.grass_avatar .inneravatar-7 div:nth-child(1):after {
}
@keyframes wind-1 {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
