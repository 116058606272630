.toast {
  color: rgb(255, 255, 255);
  position: absolute;
  width: 150px;
  top: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid black;
}

.block {
  margin-left: 5%;
  margin-right: 5%;
}

.tittle h1 {
  font-weight: bold;
  font-size: 300%;
}
.code-text {
  font-size: 85%;
}
.card-top {
  position: relative;
  /* border-bottom: black; */
  z-index: 1;
}

.cardObscurImg {
  position: absolute;
  top: 30%; /* Adjust to position the flame image higher or lower */
  left: 15%;
  z-index: 1;
  width: 15%;
}

.cardCostumeImg {
  position: absolute;
  top: 35%; /* Adjust to position the flame image higher or lower */
  left: 15%;
  z-index: 1;
  width: 10%;
}

/* .cardObscurImg {
  position: absolute;
  top: 40%; 
  left: 45%;
  z-index: 1;
  width: 15%;
} */

.card-title {
  font-weight: bold;
  font-size: 120%;
}

.card {
  height: 100% !important;
  background-color: rgba(90, 141, 186, 0.743) !important;
  /* filter: brightness(70%); */
  border-color: black !important;
  border-width: 2px !important;
  cursor: pointer;
  transition: transform 0.3s ease-in-out !important;
}

.darken {
  /* filter: brightness(100%)!important; */
  background-color: rgba(64, 151, 227, 0.916) !important;
  border-color: rgb(214, 208, 27) !important;
  border-width: 5px !important;
}
.card-body {
  padding-bottom: 0px;
  padding-top: 10px;
  font-size: 75%;
}

.card:hover {
  transform: scale(1.1);
  background-color: rgba(50, 106, 155, 0.743) !important;
  z-index: 1;
}

.btn-filtre-region,
.btn-filtre-autre {
  margin-left: 5px;
}

.formtext {
  margin-left: 10%;
}

.warning-text {
  color: red;
  text-align: center;
}

/* MODAL CSS */
.modal-content {
  color: #000 !important;
}

/* LOADING  */
.content-loading {
  display: flex;
  padding-top: 50px;
  justify-content: center !important;
}

.loading {
  justify-content: center;
  position: relative;
  height: 100px !important;
  width: 100px !important;
  background: linear-gradient(to bottom, rgb(254, 0, 1) 50%, white 50%);
  border-radius: 50%;
  border: 8px solid black;
  animation: spin 1s linear infinite;
}

.loading::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 90px;
  background: black;
  top: 40px;
  transform: translatey(-50%);
}

.loading::after {
  content: "";
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: white;
  top: 40px;
  left: 40px;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 0 8px black, inset 0 0 0 10px white,
    inset 0 0 0 12px black;
}

.snorlax-img {
  width: 15%;
}

/* Spin Animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* BtnScrollUp */
.scroll-to-top-button {
  /* display: none; */
  opacity: 0.8;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #9e9e9e;
  color: #000000;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
    border: 1px solid rgb(255, 255, 255);
  }
}

.scroll-to-top-button i {
  font-size: 40px;
  transform: scale(3);
  margin-top: 10px;
}

.scroll-to-top-button.visible {
  display: block;
}

#confirm-button,
#delete-button {
  font-weight: bold;
  transition: background-color 0.3s, transform 0.1s;
}

/* #confirm-button   {
    transform: scale(0.5) translate(-55%, -55%);;
    font-weight:200;
  } */
/* .scroll-to-top-button:hover {
    background-color: #000000;
  } */

/* Liste déroulante  */
/* .dropdown-button-gray {
  background-color: gray;
  color: white;
}

.dropdown-item-gray {
  background-color: gray;
  color: white;
}

.dropdown-item-gray:hover {
  background-color: darkgray;
} */
