.title {
  
}

.color-theme{
    position: absolute;
    top : 10px;
    right: 40px;
    border-color: black!important;
  }

  .copyright-text{
    text-align: center;
  }