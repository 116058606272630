.title {
  font-size: xx-large !important;
  font-weight: bolder!important;
}

.color-theme{
    position: absolute;
    top : 10px;
    right: 40px;
    border-color: black!important;
  }



  .nav-link {
    font-size: x-large!important;
  }